module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",

    "landing__title": "Wanna meet three spookiest versions of yourself?",
    "landing__subtitle": "Give a photo to AI if you dare 😈",
    "landing__text_1": "The results vary from creepy to somehow weirdly attractive.",
    "landing__text_2": "Sometimes dope, but rarely dull.",
    "landing__select_photo_button": "Upload photo",

    "processing_text_1": "Beware of zombies 🧟‍♂️ Oh, you're one of them 😏",
    "processing_text_2": "You never know how creepy you can look... 👻",
    "processing_text_3": "Can you feel shivers running down your spine?",
    "processing_text_4": "Things are getting really weird… 🙀",
    "processing_text_5": "Haunted houses… spooky monsters… creepy YOU! 😱",
    "processing_text_6": "Are you frightened? You should be! 😈",
    "processing_text_7": "- Knock knock! - Who's there? - Your evil twins 😈",
    "processing_text_8": "Creepy... Creepier... THE CREEPIEST! 🙀",

    "collage__title": "Save and share",
    "collage__text_guest": "This is someone’s Evil Doll, but you could have your own!",
    "collage__tab_image": "Image",
    "collage__tab_video": "Video",
    "collage__tab_day": "Halloween Art",
    "collage__tab_halloween": "Zombie",
    "collage__tab_doll": "Evil Doll",
    "collage__select_photo": "Try with your photo",
    "collage__download": "Download",
    "collage__refresh": "Refresh",

    "modal__message_day": "Add some Sugar (Skull) to your Instagram along with #photolab right now! For Day of the Dead comes but once a year.",
    "modal__message_halloween": "Spooky, but...<br>What if your creature is not the spookiest? 👀 Post it to challenge your friends to #gocreepy flashmob and let's find out together! 👻",
    "modal__message_doll": "Keep the DOLL rolling! Use #photolab to post your \"Annabelle\" to Instagram and find evil dolls made by your friends.",
    "modal_button": "Ok",
    "modal_title": "Don't stop here!",

    "error__error": "Error",

    "internal_error": "An error occurred...",
  },
};

