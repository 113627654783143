import React from 'react';
import i18n from "../i18n";
import FileChooseButton from "../components/FileChooseButton";
import routes from "../routes";
import Slider from "react-slick";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {assetUrl} from "../utils/etc";
import {webviewOpenBrowser} from "../utils/webview";

const PHOTO_LAB_LINK = "https://y3nay.app.goo.gl/gocreepypl";

export default class IndexPage extends React.Component {

  constructor(props) {
    super(props);

    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handlePhotoLabClick = this.handlePhotoLabClick.bind(this);
  }

  componentDidMount() {
    logEvent(userEvents.PAGE_INDEX);
  }

  handleFileSelected(file) {
    logEvent(userEvents.PHOTO_SELECT, {page: "index"});
    hitEvent(hits.PHOTO_SELECT);

    this.props.history.push(routes.CREATE, {file});
  }

  handlePhotoLabClick(e) {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(PHOTO_LAB_LINK);
    }
  }

  render() {
    const sliderSettings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '65px',
      responsive: [{
        breakpoint: 380,
        settings: {
          centerPadding: '45px',
        }
      }, {
        breakpoint: 340,
        settings: {
          centerPadding: '20px',
        }
      }]
    };

    return <main className="main-page">
      <div className="container">
        <h1 className="main-title" dangerouslySetInnerHTML={{__html: i18n.t("landing__title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("landing__subtitle")}} />
      </div>

      <section className="wr-slider">
        <Slider {...sliderSettings}>
          {[1, 2, 3, 4, 5, 6].map((number) => <SlideView key={number} number={number} />)}
        </Slider>
      </section>

      <div className="container">
        {/* <p dangerouslySetInnerHTML={{__html: i18n.t("landing__text_1")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("landing__text_2")}} /> */}
        <FileChooseButton
          onFileSelected={this.handleFileSelected}
          className="btn-upload-foto"
          children={i18n.t("landing__select_photo_button")} />

        <div className="footer-links">
          <a href={PHOTO_LAB_LINK} target="_blank" hidden={window.clientConfig.isWebview} onClick={this.handlePhotoLabClick}>by Photo Lab</a>
          <a href="https://pho.to/privacy" target="_blank" hidden={window.clientConfig.isWebview}>Privacy Policy</a>
        </div>
      </div>
    </main>;
  }
}

IndexPage.contextType = AppContext;

function SlideView({number}) {
  const jpg = [
    assetUrl(`assets/img/slider/img-${number}.jpg`) + " 1x",
    assetUrl(`assets/img/slider/img-${number}@2x.jpg`) + " 2x",
    assetUrl(`assets/img/slider/img-${number}@3x.jpg`) + " 3x",
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/slider/img-${number}.webp`) + " 1x",
    assetUrl(`assets/img/slider/img-${number}@2x.webp`) + " 2x",
    assetUrl(`assets/img/slider/img-${number}@3x.webp`) + " 3x",
  ].join(", ");

  return <div className="slide-container">
    <div className="slide">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={jpg} alt="Demo" />
      </picture>
    </div>
  </div>;
}
