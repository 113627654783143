import React from 'react';
import * as Sentry from "@sentry/react";
import i18n from "../i18n";
import routes from "../routes";
import * as api from "../utils/api";
import Loading from "../components/Loading";
import FileChooseButton from "../components/FileChooseButton";
import HomeButton from "../components/HomeButton";
import Share from "../components/Share";
import {assetUrl} from "../utils/etc";
import AppContext from "../contexts/AppContext";
import * as creativeUtils from "../utils/creative";
import {getLocationQueryObject} from "../utils/text";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import Modal from "../components/Modal";
import * as webviewUtils from "../utils/webview";
import ImageView from "../components/ImageView";

const FETCH_INTERVAL = 1000;

const TABS = [0, 1, 2];

export default class ResultPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      photo: null,
      creatives: null,
      defaultTabIndex: 0,
      tabIndex: 0,
    };

    this.fetchResult = this.fetchResult.bind(this);
    this.handleResultFetched = this.handleResultFetched.bind(this);
    this.handleResultFetchFailed = this.handleResultFetchFailed.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleDownloadButtonClick = this.handleDownloadButtonClick.bind(this);

    this.fecthTimer = null;
  }

  componentDidMount() {
    this.fetchResult();

    // preload popup image background
    new Image().src = assetUrl("assets/img/bg/popup-bgr.png");
  }

  componentWillUnmount() {
    clearTimeout(this.fecthTimer);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.hash !== prevProps.match.params.hash) {
      this.setState({
        isLoading: true
      }, () => {
        this.fetchResult();
      });
    }
  }

  fetchResult() {
    api.fetchPhotoByHash(this.props.match.params.hash)
      .then(this.handleResultFetched)
      .catch(this.handleResultFetchFailed);
  }

  handleResultFetchFailed(err) {
    Sentry.captureException(err);
    console.error(err);

    if (this.state.photo && this.state.creatives.filter(creativeUtils.creativeIsProcessing).length > 0) {
      this.fecthTimer = setTimeout(this.fetchResult, FETCH_INTERVAL);
      return;
    }

    this.props.history.replace({
      pathname: routes.ERROR,
      state: {message: err.message || err.description || err.name}
    });
  }

  handleResultFetched(res) {
    const query = getLocationQueryObject();
    const isNewResult = this.state.photo === null || this.state.photo.id !== res.photo.id;
    const nextState = {
      isLoading: false,
      photo: res.photo,
      creatives: res.creatives,
    };

    if (isNewResult) {
      let tabIndex;
      if (query.i) {
        tabIndex = parseInt(query.i);
        tabIndex = TABS.includes(tabIndex) ? tabIndex : 0;
      } else {
        // const processedIndex = res.creatives.findIndex(creativeUtils.creativeIsProcessed);
        // tabIndex = processedIndex > -1 ? processedIndex : 0;
        tabIndex = TABS[Math.floor(Math.random() * TABS.length)];
      }

      nextState.defaultTabIndex = tabIndex;
      nextState.tabIndex = tabIndex;

      logEvent(userEvents.PAGE_RESULT, {
        photo_id: nextState.photo.id,
        is_owner: nextState.photo.is_owner,
        is_video: nextState.creatives[tabIndex].template.is_animation,
        ref: nextState.photo.is_owner ? undefined : (query.ref || "unspecified")
      });
    }

    this.setState(nextState);

    if (nextState.creatives.filter(creativeUtils.creativeIsProcessing).length > 0) {
      this.fecthTimer = setTimeout(this.fetchResult, FETCH_INTERVAL);
    }
  }

  handleShare(provider) {
    const defaultCreative = this.state.creatives[this.state.defaultTabIndex];
    const creative = this.state.creatives[this.state.tabIndex];

    hitEvent(hits.SHARE);
    logEvent(userEvents.SHARE, {
      is_video: creative.file.is_video,
      photo_id: this.state.photo.id,
      creative_id: creative.id,
      template_id: creative.template.id,
      default_tab_template_id: defaultCreative.template.id,
      provider
    });

    api.logPhotoShare(this.state.photo.id, provider);
  }

  handleFileSelected(file) {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {page: "result"});

    this.props.history.push(routes.CREATE, {file});
  }

  handleDownloadButtonClick(e) {
    const defaultCreative = this.state.creatives[this.state.defaultTabIndex];
    const creative = this.state.creatives[this.state.tabIndex];

    hitEvent(hits.DOWNLOAD);
    logEvent(userEvents.DOWNLOAD, {
      is_video: creative.file.is_video,
      photo_id: this.state.photo.id,
      creative_id: creative.id,
      template_id: creative.template.id,
      default_tab_template_id: defaultCreative.template.id,
    });

    // const modalMessageId = [
    //   "modal__message_day",
    //   "modal__message_halloween",
    //   "modal__message_doll"
    // ][this.state.tabIndex];

    this.context.pushModal(<DownloadModal
      key="ResultPage-DownloadModal"
      messageId="modal__message_halloween"
    />);

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewShareDownload(creative.file.url);
    } else {
      const downloadUrl = new URL(this.state.photo.download_url);
      downloadUrl.searchParams.append("creative_id", creative.id);
      window.location.href = downloadUrl.toString();
    }
  }

  render() {
    if (this.state.isLoading) {
      const photo = this.props.location.state
        && this.props.location.state.photo
        && this.props.location.state.photo.file
        && this.props.location.state.photo.file.url;

      return <Loading image={photo} />;
    }

    const creative = this.state.creatives[this.state.tabIndex];
    const creativeIsProcessing = creativeUtils.creativeIsProcessing(creative);

    // const shareUrl = new URL(this.state.photo.share_url);
    // if (this.state.tabIndex > 0) {
    //   shareUrl.searchParams.set("i", "" + this.state.tabIndex);
    // }
    //
    // const downloadUrl = new URL(this.state.photo.download_url);
    // downloadUrl.searchParams.append("creative_id", creative.id);

    const isOwner = this.state.photo.is_owner;
    const isGuest = !isOwner;

    return <main className="collage-page">
      <div className="container">
        <HomeButton hidden={isGuest} page={"result"} />
        <h1 hidden={isOwner}
            className="main-title"
            dangerouslySetInnerHTML={{__html: i18n.t("landing__title")}} />
      </div>
      <div className="collage-page-content">
        {/* <h2 hidden={isGuest} children={i18n.t("collage__title")} /> */}

        <div className="tabs-container" hidden={this.state.creatives.length < 2}>
          <button
            className={this.state.tabIndex === 0 ? 'active-tab' : ''}
            onClick={() => this.setState({tabIndex: 0})}
            children={i18n.t("collage__tab_day")} />
          <button
            className={this.state.tabIndex === 1 ? 'active-tab' : ''}
            onClick={() => this.setState({tabIndex: 1})}
            children={i18n.t("collage__tab_halloween")} />
          <button
            className={this.state.tabIndex === 2 ? 'active-tab' : ''}
            onClick={() => this.setState({tabIndex: 2})}
            children={i18n.t("collage__tab_doll")} />
        </div>

        <div className="creative-holder">
          {creativeIsProcessing && <div className="creative-holder-placeholder" />}

          {/*{creativeIsProcessing && <div className="wait-video">*/}
          {/*  {isVideoTab && <img src={this.state.creatives[0].file.url} alt="Creative" />}*/}
          {/*</div>}*/}

          <CreativeView creative={creative} />

          <div className="loader-video">
            <span className="item-loader first-item-loader" />
            <span className="item-loader second-item-loader" />
            <span className="item-loader third-item-loader" />
          </div>
        </div>

        {/* <div className="container" hidden={creativeIsProcessing || isGuest}>
          <Share
            creative={creative}
            downloadUrl={downloadUrl.toString()}
            shareUrl={shareUrl.toString()}
            onShare={this.handleShare} />
        </div> */}

        <button
          className="btn-upload-foto"
          hidden={creativeIsProcessing || isGuest}
          children={i18n.t("collage__download")}
          onClick={this.handleDownloadButtonClick} />

        {/* <button
          className="btn-refresh-foto"
          hidden={creativeIsProcessing || isGuest}
          children={i18n.t("collage__refresh")} /> */}
      </div>

      <div className="container" hidden={isOwner}>
        <p className="collage-page-text" dangerouslySetInnerHTML={{__html: i18n.t("collage__text_guest")}} />
        <FileChooseButton
          onFileSelected={this.handleFileSelected}
          className="btn-upload-foto"
          children={i18n.t("collage__select_photo")} />
      </div>
    </main>;
  }
}

ResultPage.contextType = AppContext;

function CreativeView({creative, height}) {
  if (!creative.file) {
    return <React.Fragment />;
  }

  if (creative.file.is_video) {
    return <video style={{maxHeight: height}} playsInline muted loop autoPlay controls={false}>
      <source src={creative.file.url} type="video/mp4" />
    </video>;
  }

  return <ImageView image={creative.file} backgrounded />;
}

class DownloadModal extends Modal {

  renderModal() {
    // const png = [
    //   assetUrl(`assets/img/bg/popup-bgr.png`) + " 1x",
    //   assetUrl(`assets/img/bg/popup-bgr@2x.png`) + " 2x",
    //   assetUrl(`assets/img/bg/popup-bgr@3x.png`) + " 3x",
    // ].join(", ");
  
    // const webp = [
    //   assetUrl(`assets/img/bg/popup-bgr.webp`) + " 1x",
    //   assetUrl(`assets/img/bg/popup-bgr@2x.webp`) + " 2x",
    //   assetUrl(`assets/img/bg/popup-bgr@3x.webp`) + " 3x",
    // ].join(", ");
    return <div className="modal-container">
      <div className="modal">
        <img className="modal-bg" src={assetUrl('assets/img/bg/popup-bgr.png')} alt="" />
        <h3 dangerouslySetInnerHTML={{__html: i18n.t("modal_title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t(this.props.messageId)}} />

        <button className="btn-upload-foto" children={i18n.t("modal_button")} onClick={() => this.dismiss()} />
     </div>

      <img className="bg-result bg-top" src={assetUrl("assets/img/bg/top.png")} alt="" />

      <img className="bg-result bg-bottom" src={assetUrl("assets/img/bg/bottom.png")} alt="" />
    </div>;
  }

}